import React from "react";

import { Text } from "@shared/ui/text";
import { GenericLink } from "@shared/ui/GenericLink";
import { Button } from "@shared/ui/buttons/Button";
import { IconArrowLeft } from "@shared/icons/IconArrowLeft";
import { useTranslation } from "react-i18next";
import { useLanguage } from "@hooks/useLanguage";

const MastercardEventRulesPage = () => {
  const { t } = useTranslation();
  const language = useLanguage();
  const isLanguageUk = language === "uk";
  const certificateRulesLink = `/documents/certification-rules${
    isLanguageUk ? "" : "-en"
  }.pdf`;

  return (
    <div className="p-4 md:py-8 md:px-[80px] text-xs md:text-base">
      <div>
        <Button
          as="link-in"
          to="/"
          variant="secondary"
          size="medium"
          icon={IconArrowLeft}
          iconClassName="w-5 h-auto"
          className="pr-[6px] mb-5"
        />
      </div>

      <Text component="h1" className="text-2xl md:text-4xl mb-4 md:mb-8">
        {t("official-rules")}
      </Text>
      <Text component="p" className="py-2 md:py-4 text-xs md:text-base">
        {t("go-to-edopomoga")}
      </Text>
      <Text component="p" className="py-2 md:py-4 text-xs md:text-base">
        {t("to-give-help")}
      </Text>

      <ol className="py-2 px-5 md:px-10 list-decimal">
        <li className="py-1">
          {t("go-to-edopomoga-3")}{" "}
          <GenericLink
            type="outer"
            to={`https://edopomoga.gov.ua${isLanguageUk ? "" : "/en.html"}`}
            className="text-shamrock underline"
          >
            edopomoga.gov.ua
          </GenericLink>
          .
        </li>
        <li
          className="py-1"
          dangerouslySetInnerHTML={{ __html: t("rules-2") }}
        />
        <li
          className="py-1"
          dangerouslySetInnerHTML={{ __html: t("rules-3") }}
        ></li>
        <li className="py-1">{t("rules-4")}</li>
        <li
          className="py-1"
          dangerouslySetInnerHTML={{ __html: t("rules-5") }}
        ></li>
        <li className="py-1">{t("rules-6")}</li>
        <li
          className="py-1"
          dangerouslySetInnerHTML={{ __html: t("rules-7") }}
        ></li>
        <li className="py-1">{t("rules-8")}</li>
        <li
          className="py-1"
          dangerouslySetInnerHTML={{ __html: t("rules-9") }}
        ></li>
        <li className="py-1">
          {t("rules-10")}
          <ul>
            <li
              className="before:content-['-'] before:inline-block"
              dangerouslySetInnerHTML={{ __html: " " + t("rules-10-1") }}
            />
            <li
              className="before:content-['-'] before:inline-block"
              dangerouslySetInnerHTML={{ __html: " " + t("rules-10-2") }}
            />
            <li
              className="before:content-['-'] before:inline-block"
              dangerouslySetInnerHTML={{ __html: " " + t("rules-10-3") }}
            />
          </ul>
        </li>
        <li className="py-1">{t("rules-11")}</li>
        <li className="py-1">{t("rules-12")}</li>
        <li className="py-1">{t("rules-13")}</li>
        <li className="py-1">{t("rules-14")}</li>
        <li className="py-1">{t("rules-15")}</li>
      </ol>

      <p
        className="py-2 md:py-4 text-xs md:text-base"
        dangerouslySetInnerHTML={{ __html: t("more-details") }}
      />

      <Text
        component="small"
        className="block p-1 text-gray text-xxxs md:text-sm"
      >
        * {t("sub-1")}
      </Text>
    </div>
  );
};

export default MastercardEventRulesPage;
